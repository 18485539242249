<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="{ errors }"
              name="Tipo de Atendimento"
              rules="required"
            >
              <b-form-group
                label="Tipo de Atendimento"
                label-for="v-tipoAtendimento"
              >
                <v-select
                  v-model="form.tipoAtendimento"
                  :reduce="(comboTiposAtendimento) => comboTiposAtendimento.id"
                  label="nome"
                  :options="comboTiposAtendimento"
                  :state="errors.length > 0 ? false : null"
                  data-cy="tipo"
                  @input="loadEspecialidades"
                />
                <small data-cy="v-tipoAtendimento" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="{ errors }"
              name="Especialidades"
              rules="required"
            >
              <b-form-group label="Especialidades" label-for="v-especialidade">
                <v-select
                  v-model="form.especialidade"
                  :reduce="(comboTiposAtendimento) => comboTiposAtendimento.id"
                  label="descricao"
                  :options="comboEspecialidades"
                  :state="errors.length > 0 ? false : null"
                  data-cy="especialidade"
                  @input="loadProfissional"
                />
                <small data-cy="v-especialidade" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="4">
            <validation-provider
              #default="{ errors }"
              name="Profissional"
              rules="required"
            >
              <b-form-group label="Profissional" label-for="v-profissional">
                <v-select
                  v-model="form.profissional"
                  label="nome"
                  :options="comboProfissionals"
                  :state="errors.length > 0 ? false : null"
                  data-cy="profissional"
                  @input="getAgendas"
                />
                <small data-cy="v-profissional" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="12">
            <validation-provider
              #default="{ errors }"
              name="solicitante"
              rules="required"
            >
              <b-form-group label="Solicitante" label-for="v-solicitante">
                <v-select
                  v-model="form.registro"
                  label="nome"
                  :filterable="false"
                  :options="comboRegistros"
                  :selected="form.registro"
                  @search="onSearchPaciente"
                  ><template slot="no-options">
                    Pesquisa registro por nome, cpf ou matrícula
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small data-cy="v-solicitante" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!--unidadeAtendimento -->
          <b-col cols="12" lg="12">
            <b-form-group
              label="Unidade Atendimento"
              label-for="v-unidadeAtendimento"
            >
              <v-select
                v-model="form.unidadeAtendimento"
                :reduce="
                  (comboUnidadeAtendimento) => comboUnidadeAtendimento.id
                "
                label="nome"
                :options="comboUnidadeAtendimento"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12">
            <b-form-group label="Observações" label-for="v-observacao">
              <b-form-textarea
                id="v-observacao"
                v-model="form.observacao"
                row="2"
                placeholder="Digite aqui suas observações"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="agendaProfissional.length > 0" cols="12" lg="6">
            <b-calendar
              v-model="form.dataAtendimento"
              class="p-1"
              block
              :min="minDate"
              :max="maxDate"
              :date-disabled-fn="dateDisabled"
              :date-info-fn="dateClass"
              locale="pt-Br"
              @input="getHorarioSelecionado"
            />
          </b-col>

          <b-col v-else class="p-1 text-center" cols="12" lg="12">
            Nenhum Profissional selecionado ou não existe vagas para o
            profissional selecionado
          </b-col>

          <b-col v-if="agendaProfissional.length > 0 && !atendimentoAvulso" cols="12" lg="6">
            <b-form-group label="Horarios" label-for="v-unidadeAtendimento">
              <v-select
                v-model="form.hora"
                :options="comboProfissiocomboHorarioAgendanalsFiltrado"
                :disabled="!form.dataAtendimento"
              />
            <span v-if="form.dataAtendimento" data-cy="v-solicitante" class="text-primary h5">
                 Vagas Disponiveis para a especialidade selecionada <b-badge variant="primary mt-1">{{ vagasDisponiveis }}</b-badge>
            </span>
            </b-form-group>
          </b-col>
          <b-col
            v-if="atendimentoAvulso"
            cols="12" sm="12" lg="6"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group label="Atendimento Avulso">
                <b-form-checkbox
                  v-model="showHoraAvulso"
                  :checked="showHoraAvulso"
                  name="check-button"
                  switch
                  inline
                >
                  {{ showHoraAvulso ? 'Sim' : 'Não' }}
                </b-form-checkbox>
              </b-form-group>
              </b-col>
              <b-col v-if="showHoraAvulso" cols="12"> 
              <validation-provider
                #default="{ errors }"
                name="Hora"
                rules="required"
              >
                <b-form-group label="Hora Avulso" label-for="v-hora">
                  <flat-pickr
                    v-model="form.hora"
                    name="fieldDate"
                    :config="{
                      altInput: true,
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      time_24hr: true
                    }"
                  />
                  <small data-cy="v-hora-validate" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
              </b-col>
            </b-row>
          </b-col>

          <!-- submit and reset -->
           <ButtonsFormVue
          :disable-form="false"
          :loading-button="loading"
          :permission-insert="verificarPermissao('ROLE_ATEND_INSERIR')"
          :permission-edit="verificarPermissao('ROLE_ATEND_ATUALIZAR')"
          @save="save"
          @edit="() => {}"
          @cancel="cancelar"
        />
          
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BCard,
  BFormTextarea,
  BCalendar,
  BFormCheckbox,
  BBadge
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import mixinsGlobal from '@/mixins'
import mixinsAtendimento from '@/mixins/atendimento.js'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import _ from 'lodash'

export default {
  name: 'FormAtendimento',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
    BCalendar,
    ButtonsFormVue,
    flatPickr,
    BFormCheckbox,
    BBadge
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal, mixinsAtendimento],
  computed: {
    ...mapState('combosModule', {
      comboTiposAtendimento: (state) => state.comboTiposAtendimento
    })
  },
  async mounted() {
    await this.$store.dispatch('combosModule/loadComboTipoAtendimento')
    this.loadComboUnidadeAtendimento()
  },
  methods: {
    loadEspecialidades() {
      this.form.especialidade = null
      this.form.profissional = null
      this.agendaProfissional = []
      this.form.dataAtendimento = null
      if (!this.form.tipoAtendimento) {
        return
      }
      this.loadComboEspecialidades(this.form.tipoAtendimento)
    },
    loadComboEspecialidades(id) {
      this.comboEspecialidades = []
      this.$store
        .dispatch('combosModule/loadComboEspecialidadesByIdTipoAtendimento', id)
        .then((response) => {
          this.comboEspecialidades = response.data
        })
    },
    loadProfissional(idEspecialidade) {
      this.form.profissional = null
      this.agendaProfissional = []
      this.form.dataAtendimento = null
      if (!idEspecialidade) {
        return
      }
      this.comboProfissionals = []
      this.$store
        .dispatch(
          'combosModule/loadComboProfissionalByIdEspecialidade',
          idEspecialidade
        )
        .then((response) => {
          this.comboProfissionals = response.data.map((item) => ({
            id: item.id,
            nome: item.registro.nome
          }))
        })
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      if (data.id) {
        return objectSave
      }
      const obj = {
        id: objectSave.id ? objectSave.id : undefined,
        observacao: objectSave.observacao,
        situacao: objectSave.situacao,
        registro: objectSave.registro.id,
        unidadeAtendimento: objectSave.unidadeAtendimento,
        especialidade: objectSave.especialidade,
        avulso: objectSave.avulso,
        tipoAtendimento: objectSave.tipoAtendimento,
        hora: objectSave.hora.value
      }

      if (this.showHoraAvulso) {
        obj.profissional = objectSave.profissional.id
        obj.data = objectSave.dataAtendimento
        obj.hora = objectSave.hora
      }
      return obj
    },
    save() {
      this.$refs.form.validate().then((success) => {
        const objectSave = this.prepareForSave(this.form)
        let metodoDefault = 'insert'
        if (this.atendimentoAvulso && !this.showHoraAvulso) { 
          console.log('teste')
          this.MensagemError('Avulso', 'Marque o campo Atendimento Avulso como sim e digite o horário')

          return
        }
        if (this.showHoraAvulso) {
          metodoDefault = 'insertAvulso'
        }
        if (success) {
          this.loading = true
          this.$store
            .dispatch(`atendimentoModule/${metodoDefault}`, objectSave)
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
                  `${response.response.data.errors[0].message}`
                )
                this.showForm = true
                this.loading = false
                return
              }
              if (response.error) {
                this.MensagemError('Falha ao Inserir', `${response.message}`)
                this.showForm = true
                this.loading = false
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cancelar()
            })
        }
      })
    },
    cancelar() {
      this.loading = false
      this.$emit('cancelar')
      this.cleanObjeto()
    },
    cleanObjeto() {
      this.form = {
        numeroAtendimento: '',
        observacao: '',
        situacao: '',
        profissional: '',
        registro: '',
        unidadeAtendimento: '',
        especialidade: '',
        avulso: true,
        tipoAtendimento: '',
        dataAtendimento: '',
        hora: ''
      }
    },
    loadComboUnidadeAtendimento() {
      this.$store
        .dispatch('combosModule/loadComboUnidadeAtendimento')
        .then((response) => {
          this.comboUnidadeAtendimento = response.data.map((item) => ({
            id: item.id,
            nome: item.descricao
          }))
        })
    },
    onSearchPaciente(search, loading) {
      loading(true)
      this.searchPaciente(loading, search, this)
    },
    searchPaciente: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'atendimentoModule/searchPaciente',
        search
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`
        )
        return
      }

      if (registrosEncontrados.data.length === 0) {
        vm.MensagemError('Zero registros', 'Nenhum registro encontrado')
        return
      }
      vm.comboRegistros = registrosEncontrados.data
      loading(false)
    }, 350),
    getAgendas() {
      if (!this.form.profissional) {
        this.agendaProfissional = []
        return
      }
      this.$store
        .dispatch(
          'atendimentoModule/getAgendaProfissional',
          this.form.profissional.id
        )
        .then(async (response) => {
          this.agendaProfissional = response.data
          if (this.agendaProfissional.length > 0) {
            this.minAndMaxDates()
            await this.LoadComboHarario()
          }
        })
    }
  },
  data() {
    return {
      required,
      loading: false,
      titleForm: 'Cadastro de Atendimentos',
      minDate: '',
      maxDate: '',
      comboProfissionals: [],
      comboRegistros: [],
      comboAgenda: [],
      form: {
        numeroAtendimento: '',
        observacao: '',
        situacao: '',
        profissional: '',
        registro: '',
        unidadeAtendimento: '',
        especialidade: '',
        avulso: true,
        tipoAtendimento: '',
        dataAtendimento: '',
        hora: ''
      },
      comboUnidadeAtendimento: [],
      comboEspecialidades: [],
      agendaProfissional: [],
      comboProfissiocomboHorarioAgendanals: [],
      comboProfissiocomboHorarioAgendanalsFiltrado: [],
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
